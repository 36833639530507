var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('filters',{attrs:{"fin":true,"add":true,"tags":true},on:{"filtered-items":_vm.filter,"add-button-clicked":_vm.addTransaction}}),_c('b-card',[_c('b-table',{attrs:{"hover":"","show-empty":"","fields":_vm.fields,"items":_vm.GET_TRANSACTIONS.results,"style-class":"slots_text_align vgt-table bordered","empty-text":_vm.$t('general.no_data_to_view')},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(label))+" ")]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"d-flex align-items-center no-wrap"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon p-25",attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.deleteTransaction(data.item.id)}}},[_c('feather-icon',{attrs:{"size":"22","icon":"Trash2Icon"}})],1)],1)]}},{key:"cell(client)",fn:function(ref){
var item = ref.item;
return [_c('b-avatar',{staticClass:"mr-50",attrs:{"src":item.client.photo}}),_vm._v(" "+_vm._s(item.client.full_name)+" ")]}},{key:"cell(balance)",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(item.client.balance)+" ")])]}},{key:"cell(amount)",fn:function(ref){
var value = ref.value;
return [_c('p',{staticClass:"text-success"},[_vm._v(" +"+_vm._s(value)+" ")])]}},{key:"cell(login)",fn:function(data){return [_vm._v(" "+_vm._s(data.value)+" ")]}}])}),[_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.$t('general.show_from')))]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['1', '5', '10', '15']},on:{"change":_vm.changePerPage},model:{value:(_vm.params.page_size),callback:function ($$v) {_vm.$set(_vm.params, "page_size", $$v)},expression:"params.page_size"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('general.show_to'))+" ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"total-rows":_vm.GET_TRANSACTIONS.count,"per-page":_vm.params.page_size,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"change":_vm.changePage},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.params.page),callback:function ($$v) {_vm.$set(_vm.params, "page", $$v)},expression:"params.page"}})],1)])]],2),_c('operations',{attrs:{"is-show-modal":_vm.isShowModal},on:{"update:isShowModal":function($event){_vm.isShowModal=$event},"update:is-show-modal":function($event){_vm.isShowModal=$event},"refresh":_vm.refresh}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }