<template>
  <b-sidebar
    id="operation-modal"
    v-model="sidebar"
    bg-variant="white"
    no-close-on-backdrop
    right
    backdrop
    shadow
    width="680px"
    @hidden="beforeHideSidebar"
    @shown="showModal"
  >
    <template #header="{ hide }">
      <feather-icon
        icon="XIcon"
        class="mt-1"
        @click="hide"
      />
      <h6 class="mb-0 mt-1 ml-2">
        {{ $t('finance.income') }}
      </h6>
    </template>
    <validation-observer ref="infoOperation">
      <b-overlay
        :show="finance_loading"
        opacity="0.6"
        rounded="lg"
      >
        <b-col
          md="12"
          class="mt-2"
        >

          <b-row class="pb-1">
            <b-col md="4">
              <span>{{ $t('finance.payment_date') }}</span>
            </b-col>
            <b-col md="8">
              <validation-provider
                #default="{ errors }"
                :name="$t('finance.payment_date')"
                rules="required"
              >
                <VueDatePicker
                  id="data"
                  v-model="transaction.paid_at"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('finance.payment_date')"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row class="pb-1">
            <b-col md="4">
              <span>{{ $t('finance.client') }}</span>
            </b-col>
            <b-col md="8">
              <validation-provider
                #default="{ errors }"
                :name="$t('finance.client')"
                rules="required"
              >
                <app-select
                  v-model="transaction.client"
                  :get-option-label="(item) => `${item.contact.id} ${item.contact.full_name}`"
                  :options="GET_CLIENTS_LIST"
                  :filters="{ role__unique_name: 'student' }"
                  :lazy-fetch-data="FETCH_CLIENTS_LIST"
                  :reduce="opt => opt.contact.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row class="pb-1">
            <b-col md="4">
              <span>{{ $t('finance.sum') }}</span>
            </b-col>
            <b-col md="8">
              <validation-provider
                #default="{ errors }"
                :name="$t('finance.sum')"
                rules="required"
              >
                <cleave
                  id="number"
                  v-model="transaction.amount"
                  class="form-control"
                  :raw="true"
                  :options="options"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row class="pb-1">
            <b-col md="4">
              <span class="pt-1">{{ $t('finance.description') }}</span>
            </b-col>
            <b-col md="8">
              <validation-provider
                #default="{ errors }"
                :name="$t('finance.description')"
              >
                <b-form-textarea
                  v-model="transaction.description"
                  rows="2"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              md="12"
              class="pt-2 d-flex justify-content-end"
            >
              <b-button
                variant="secondary"
                class="mr-1"
                @click="close"
              >
                {{ $t('general.cancel') }}
              </b-button>
              <b-button
                variant="success"
                @click="onSubmit"
              >
                {{ $t('general.save') }}
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-overlay>
    </validation-observer>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BRow,
  BCol,
  BFormTextarea,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import Cleave from 'vue-cleave-component'
import { mapActions, mapGetters } from 'vuex'
import VueDatePicker from '@/components/VueDatePicker.vue'
import AppSelect from '@/components/AppSelect.vue'

export default {
  components: {
    BSidebar,
    BRow,
    BCol,
    BFormTextarea,
    BButton,
    VueDatePicker,
    ValidationObserver,
    ValidationProvider,
    Cleave,
    BOverlay,
    AppSelect,
  },
  props: {
    isShowModal: {
      type: Boolean,
      default: false,
    },
    operation: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      finance_loading: false,
      required,
      sidebar: false,
      type: 'income',
      transaction: {
        type: 'income',
        paid_at: new Date().toISOString().split('T')[0],
        amount: null,
        client: null,
        description: '',
      },
      options: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
    }
  },
  computed: {
    ...mapGetters('finance', ['GET_CLIENTS_LIST']),
  },
  watch: {
    isShowModal(val) {
      this.sidebar = val
    },
  },
  methods: {
    ...mapActions('finance', [
      'FETCH_CLIENTS_LIST',
      'FETCH_TRANSACTIONS',
      'CREATE_TRANSACTIONS',
      'UPDATE_TRANSACTIONS',
    ]),
    onSubmit() {
      this.$refs.infoOperation.validate().then(async success => {
        if (!success) return
        this.CREATE_TRANSACTIONS(this.transaction)
          .then(() => {
            this.$emit('refresh')
            this.$_successToast(this.$t('general.added'))
            this.close()
          })
          .catch(() => {
            this.$_errorToast(this.$t('general.error'))
          })
      })
    },
    typeChange(type) {
      this.transaction.type = type
    },
    close() {
      this.sidebar = false
      this.$nextTick(() => {
        this.$refs.infoOperation.reset()
      })
    },

    showModal() {
      console.log('show')
    },

    beforeHideSidebar() {
      this.$refs.infoOperation.reset()
      this.$emit('update:isShowModal', false)
    },
  },
}
</script>
