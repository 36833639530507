<template>
  <div>
    <filters
      :fin="true"
      :add="true"
      :tags="true"
      @filtered-items="filter"
      @add-button-clicked="addTransaction"
    />
    <b-card>
      <b-table
        hover
        show-empty
        :fields="fields"
        :items="GET_TRANSACTIONS.results"
        style-class="slots_text_align vgt-table bordered"
        :empty-text="$t('general.no_data_to_view')"
      >
        <template #head()="{label}">
          {{ $t(label) }}
        </template>
        <template
          #cell(actions)="data"
        >
          <div class="d-flex align-items-center no-wrap">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="btn-icon p-25"
              size="sm"
              @click="deleteTransaction(data.item.id)"
            >
              <feather-icon
                size="22"
                icon="Trash2Icon"
              />
            </b-button>
          </div>
        </template>

        <template
          #cell(client)="{ item }"
        >

          <b-avatar
            :src="item.client.photo"
            class="mr-50"
          />
          {{ item.client.full_name }}
        </template>

        <template
          #cell(balance)="{ item }"
        >
          <p class="text-primary">
            {{ item.client.balance }}
          </p>
        </template>

        <template
          #cell(amount)="{ value }"
        >
          <p class="text-success">
            +{{ value }}
          </p>
        </template>
        <template
          #cell(login)="data"
        >
          {{ data.value }}
        </template>
      </b-table>

      <!-- pagination -->
      <!-- slot-scope="props" -->
      <template>
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">{{ $t('general.show_from') }}</span>
            <b-form-select
              v-model="params.page_size"
              :options="['1', '5', '10', '15']"
              class="mx-1"
              @change="changePerPage"
            />
            <span class="text-nowrap"> {{ $t('general.show_to') }} </span>
            <!-- из {{ props.total }} -->
          </div>
          <div>
            <b-pagination
              v-model="params.page"
              :total-rows="GET_TRANSACTIONS.count"
              :per-page="params.page_size"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="changePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </b-card>
    <operations
      :is-show-modal.sync="isShowModal"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import {
  BTable,
  BPagination,
  BCard,
  BFormSelect,
  BAvatar,
  BButton,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import Filters from '@/components/filters.vue'
import Operations from './components/sidebar/Operations.vue'

export default {
  components: {
    Filters,
    Operations,
    BTable,
    BPagination,
    BFormSelect,
    BCard,
    BAvatar,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      params: {
        page_size: 10,
        page: 1,
        ordering: null,
        search: null,
        client: null,
        from_date: null,
        to_date: null,
        tags: null,
      },
      isShowModal: false,
      rows: 1,
      items: [],
      fields: [
        {
          label: 'ID',
          key: 'id',
        },
        {
          label: 'finance.client',
          key: 'client',
        },
        {
          label: 'finance.payment_date',
          key: 'paid_at',
          formatter: data => new Date(data).toLocaleString().split(',')[0],
        },
        {
          label: 'finance.current_balance',
          key: 'balance',
        },
        {
          label: 'finance.sum',
          key: 'amount',
        },
        {
          label: 'finance.description',
          key: 'description',
        },
        {
          label: 'general.actions',
          key: 'actions',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('finance', ['GET_TRANSACTIONS']),
  },
  mounted() {
    this.FETCH_TRANSACTIONS(this.params)
  },
  methods: {
    ...mapActions('finance', [
      'FETCH_TRANSACTIONS',
      'CREATE_TRANSACTIONS',
      'UPDATE_TRANSACTIONS',
      'DELETE_TRANSACTIONS',
    ]),
    refresh() {
      this.FETCH_TRANSACTIONS(this.params)
    },
    addTransaction() {
      this.isShowModal = true
    },
    deleteTransaction(id) {
      this.$_showAreYouSureModal()
        .then(success => {
          if (success) {
            this.DELETE_TRANSACTIONS(id)
              .then(() => {
                this.refresh()
                this.$_successToast(this.$t('general.added'))
              })
              .catch(() => {
                this.$_errorToast(this.$t('general.error'))
              })
          }
        })
    },
    changePerPage(page_size) {
      this.params.page_size = page_size
      this.params.page = 1
      this.FETCH_TRANSACTIONS(this.params)
    },
    changePage(value) {
      this.params.page = value
      this.FETCH_TRANSACTIONS(this.params)
    },
    filter(val) {
      // const { from_date, to_date } = this.params
      this.params = {
        page: this.params.page,
        page_size: this.params.page_size,
        // from_date: new Date(from_date).toISOString().split('T')[0],
        // to_date: new Date(to_date).toISOString().split('T')[0],
        ...val,
      }

      this.FETCH_TRANSACTIONS(this.params)
    },
  },
}
</script>
